import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import useAuth from "../hooks/useAuth";

import { ACCOUNT_LOGIN } from "./RoutesConstant";

const PrivateRoute = () => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  return isAuthenticated ? <Outlet /> : <Navigate to={ACCOUNT_LOGIN} state={{ from: location }} replace />;
};

export default PrivateRoute;

export const URLParamsCheck = ({ children }) => {
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const campaignId = searchParams.get("campaignId");
    const storeId = searchParams.get("storeId");

    if (!campaignId || !storeId) {
      window.history.replaceState({}, "", "/welcome");
      alert("Invalid URL: please rescan or use a valid link.");
    }
  }, []);

  return children;
};
